//
// Alert
//

.alert {
  font-size: $font-size-sm;
}

// Alert heading

.alert-heading {
  font-weight: $font-weight-bold;
  font-size: $h4-font-size;
  margin-top: 0.15rem;
}

// Alert icon

.alert-icon {
  font-size: 1.25rem;
  margin-right: 1.25rem;
  display: inline-block;
  vertical-align: middle;

  i.ni {
    position: relative;
    top: 2px;
  }
}

// Alert text next to an alert icon

.alert-text {
  display: inline-block;
  vertical-align: middle;
}

// Alert links

[class*="alert-"] {
  .alert-link {
    color: $white;
    border-bottom: 1px dotted rgba($white, 0.5);
  }
}

// Alert List Color
.alert-success
{
    color: #fff !important;
    border-color: #4fd69c !important; 
    background-color: #4fd69c !important;
}
.alert-success hr
{
    border-top-color: #3ad190 !important;
}
.alert-success .alert-link
{
    color: #24a46d !important;
}

.alert-danger
{
  color: #fff !important;
  border-color: #f75676 !important; 
  background-color: #f75676 !important;
}
.alert-danger hr
{
  border-top-color: #f63e62 !important;
}
.alert-danger .alert-link
{
  color: #ec0c38 !important;
}

.alert-warning
{
  color: #fff !important;
  border-color: #fc7c5f !important; 
  background-color: #fc7c5f !important;
}
.alert-warning hr
{
  border-top-color: #fc6846 !important;
}
.alert-warning .alert-link
{
  color: #fa3a0e !important;
}