//
// Variables
//

//
// Base variables
//

// Global settings

$enable-caret: true;
$enable-rounded: true;
$enable-gradients: false;
$enable-transitions: true;
$enable-grid-classes: true;
$enable-print-styles: true;

// Shadows

$enable-shadows: true;

// Color system

$white: #fff;
$white-75: #ffffffbf;
$gray-100: #F5F5F5;
$gray-200: #D1D1D1;
$gray-300: #787878;
$gray-400: #716f6f;
$gray-500: #696969;
$gray-600: #8898aa; // Line footer color
$gray-700: #5F5F5F; // Line p color
$gray-800: #32325d; // Line heading color
$gray-900: #545454;
$black: #313131;
$black-secondary: #404040;
$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
  ),
  $grays
);

$blue: #5e72e4;
$indigo: #5603ad;
$purple: #8965e0;
$pink: #f3a4b5;
$red: #f5365c;
$orange: #fb6340;
$yellow: #ffd600;
$green: #1ED974;
$teal: #11cdef;
$cyan: #2bffc6;

$colors: ();
$colors: map-merge(
  (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "white-75": $white-75,
    "gray-100": $gray-100,
    "gray-200": $gray-200,
    "gray-300": $gray-300,
    "gray-400": $gray-400,
    "gray-500": $gray-500,
    "gray-600": $gray-600,
    "gray-700": $gray-700,
    "gray-800": $gray-800,
    "gray-900": $gray-900,
  ),
  $colors
);

$default: #172b4d;
$primary: #3DC0F0;
$git-yellowgreen: #70b603;
$git-blue: rgb(73, 229, 255);
$git-blue-secondary: rgb(61, 192, 240);
$secondary: #6477E6;
$success: $green;
$git-success: #4BD48A;
$info: $teal;
$warning: $orange;
$danger: $red;
$git-danger: #F4355C;
$git-purple: #5e72e4;                               
$light: $gray-500;
$dark: rgb(75, 75, 75);
$darker: rgb(38, 38, 38);


$bg-linier-primary: linear-gradient(180deg, $git-blue 0%, $git-blue-secondary 100%);
$bg-gradient-btn: linear-gradient(225deg, #3278FF 0%, #3899F4 100%);
$bg-linier-darkmode: linear-gradient(270deg, $dark 0%, $darker 100%);
$sun-morning-color: linear-gradient(135deg, #FAFF00 0%, #FFD53F 100%);
$sun-sunset-color: linear-gradient(135deg, #FFD600 0%, #F48400 100%);

:root {
  --bg-gradient-btn : #{$bg-gradient-btn};
  --bg-linier-primary : #{$bg-linier-primary};
  --bg-linier-darkmode : #{$bg-linier-darkmode};
  --sun-morning-color : #{$sun-morning-color};
  --sun-sunset-color : #{$sun-sunset-color};
  --left-wrapper-width: 4.75rem;
  --as-of-date-opacity: 0;
  --as-of-date-z-index: 1;
}

$facebook: #3b5999;
$twitter: #1da1f2;
$google-plus: #dd4b39;
$instagram: #e4405f;
$pinterest: #bd081c;
$youtube: #cd201f;
$slack: #3aaf85;
$dribbble: #ea4c89;
$github: #222222;
$vimeo: #04a0f0;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "default": $default,
    "primary": $primary,
    "git-yellowgreen": $git-yellowgreen,
    "git-blue": $git-blue,
    "git-blue-secondary": $git-blue-secondary,
    "secondary": $secondary,
    "success": $success,
    "git-success": $git-success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "git-danger": $git-danger,
    "git-purple": $git-purple,
    "white": $white,
    "git-sidebar-bg-primary": #ffffffbf,
    "git-sidebar-bg-secondary": #e7e6e6,
    "git-menu-primary": #313131,
    "git-menu-secondary": #787878,
    "git-menu-category": #8392eb,
    "git-body-color": #696969,
    "git-body-bg": #F5F5F5,
    "git-shadowbox-color": rgba(0, 0, 0, 0.05),
    "git-label-text-muted": #8898aa,
    "git-dashboard-card-bg-1": #FFF,
    "git-dashboard-card-bg-2": rgba(255, 255, 255, 0.88),
    "git-color-angle": #1F2667,
    "git-field-bg": $white,
    "git-field-label": #313131,
    "git-field-multiple-file-data-label": blue,
    "git-field-toggle-content": #716f6f,
    "git-field-label-focus": #3788d8,
    "git-field-label-hover-disabled": #696969,
    "git-field-label-hover-bg-disabled": #E9ECEF,
    "git-field-label-bg": #ffffff,
    "git-field-color": $black,
    "git-quill-editor-tool-list-field": #ffffff,
    "git-quill-editor-field": #ffffff,
    "git-quill-editor-placeholder": #adb5bd,
    "git-retry-error-bg": #f5f5f5,
    "git-home-task-wrapper": #EBF9FF,
    "git-datatable-header": #F1F4F9,
    "git-datatable-body-even": #F4F8FF,
    "git-datatable-body-odd": #F1F4F9,
    "git-datatable-active-row": #c4f1de,
    "git-datatable-footer": #FFFFFF,
    "git-hierarchy-bg-first-color": #008B8B,
    "git-hierarchy-bg-second-color": #E0FFFF,
    "git-hierarchy-box-shadow": lightgray,
    "git-btn-hover-hierarchy": #5e72e4,
    "git-talent-matrix-bg-wrapper-blue": rgba(243, 247, 255, 1),
    "git-talent-matrix-bg-wrapper-purple": rgba(245, 243, 255, 1),
    "git-talent-matrix-bg-wrapper-pink": rgba(253, 243, 255, 1),
    "git-talent-matrix-bg-wrapper-yellow": rgba(255, 246, 243, 1),
    "git-calendar-sat-sun":rgba(242, 242, 242, 1),
    "git-skeleton":lightgray,
    "git-skeleton-body":lightgray,
    "git-webkit-scrollbar": rgb(229, 229, 229),
    "git-webkit-scrollbar-thumb": rgb(180, 180, 180),
    "git-webkit-scrollbar-thumb-hover": rgb(154, 154, 154),
    "git-disabled-form-content-wrapper": rgba(0, 0, 0, 0.2),
    "git-btn-primary": $secondary,
    "git-card-bg": #FFFFFF,
    "black-secondary": $black-secondary,
    "neutral": $white,
    "dark": $dark,
    "darker": $darker,
  ),
  $theme-colors
);

$brand-colors: ();
$brand-colors: map-merge(
  (
    "facebook": $facebook,
    "twitter": $twitter,
    "google-plus": $google-plus,
    "instagram": $instagram,
    "pinterest": $pinterest,
    "youtube": $youtube,
    "slack": $slack,
    "dribbble": $dribbble,
    "github": $github,
    "vimeo": $vimeo,
  ),
  $brand-colors
);

$shape-colors: ();
$shape-colors: map-merge(
  (
    "default": #32325d,
    "primary": #5533ff,
    "secondary": #24b47e,
    "neutral": #e9ecef,
    "blue-gray": #b2cbe1,
  ),
  $shape-colors
);

$shapes-primary-colors: ();
$shapes-primary-colors: map-merge(
  (
    "step-1-gradient-bg": #281483,
    "step-2-gradient-bg": #8f6ed5,
    "step-3-gradient-bg": #d782d9,
    "span-1-bg": #53f,
    "span-2-bg": #4553ff,
    "span-3-bg": #4f40ff,
    "span-4-bg": #25ddf5,
    "span-5-bg": #1fa2ff,
  ),
  $shapes-primary-colors
);

$shapes-default-colors: ();
$shapes-default-colors: map-merge(
  (
    "step-1-gradient-bg": #7795f8,
    "step-2-gradient-bg": #6772e5,
    "step-3-gradient-bg": #555abf,
    "span-1-bg": #7795f8,
    "span-2-bg": #7b9aff,
    "span-3-bg": #6f8ff8,
    "span-4-bg": #76eea7,
    "span-5-bg": #6adaff,
  ),
  $shapes-default-colors
);

$shapes-light-colors: ();
$shapes-light-colors: map-merge(
  (
    "step-1-gradient-bg": #b2cbe1,
    "step-2-gradient-bg": #f6f9fc,
    "step-3-gradient-bg": #f6f9fc,
    "span-1-bg": #b4cce1,
    "span-2-bg": #c5dbef,
    "span-3-bg": #b9d5ed,
    "span-4-bg": #74e4a2,
    "span-5-bg": #008169,
  ),
  $shapes-light-colors
);

$shapes-dark-colors: ();
$shapes-dark-colors: map-merge(
  (
    "step-1-gradient-bg": #32325d,
    "step-2-gradient-bg": #32325d,
    "step-3-gradient-bg": #32325d,
    "span-1-bg": #2e2e57,
    "span-2-bg": #2b2b58,
    "span-3-bg": #25254d,
    "span-4-bg": #d782d9,
    "span-5-bg": #008169,
  ),
  $shapes-dark-colors
);

// Translucent color variations
$translucent-color-opacity: 0.6;

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8%;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 200;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900;
$yiq-text-light: $white;

// Action colors

$star-rating-color: $gray-400;
$star-rating-color-active: $yellow;

$favorite-color: $yellow;
$like-color: $blue;
$love-color: $red;

// Body

$body-bg: #f8f9fe;
$body-color: $gray-700;

// Sections

$section-colors: ();
$section-colors: map-merge(
  (
    "primary": $body-bg,
    "secondary": $secondary,
    "light": $gray-400,
    "dark": $dark,
    "darker": $darker,
  ),
  $section-colors
);

// Links

$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Spacing

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    -9: - ($spacer * 10),
    -8: - ($spacer * 8),
    -7: - ($spacer * 6),
    -6: - ($spacer * 4.5),
    -5: - ($spacer * 3),
    -4: - ($spacer * 1.5),
    -3: -$spacer,
    -2: - ($spacer * 0.5),
    -1: - ($spacer * 0.25),
    0: 0,
    1: ($spacer * 0.25),
    2: ($spacer * 0.5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    6: ($spacer * 4.5),
    7: ($spacer * 6),
    8: ($spacer * 8),
    9: ($spacer * 10)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.

$sizes: ();
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
  ),
  $sizes
);

//
// Components
//

$shape-height-xl: 1.5;
$shape-height-lg: 1.5;
$shape-height-sm: 1.5;

// Border

$border-width: 1px;
$border-color: $gray-200;

$border-radius: 0.375rem;
$border-radius-xl: 0.5rem;
$border-radius-lg: 0.4375rem;
$border-radius-sm: 0.25rem;

$box-shadow-sm: 0 0 0.5rem rgba($gray-600, 0.075);
$box-shadow: 0 0 2rem 0 rgba($gray-600, 0.15);
$box-shadow-lg: 0 0 3rem rgba($gray-600, 0.175);

// Component - main styles for most of the components

$component-active-color: $white;
$component-active-bg: theme-color("primary");
$component-active-border-color: theme-color("primary");

$component-hover-color: $gray-300;
$component-hover-bg: $gray-300;
$component-hover-border-color: $gray-300;

// Caret

$caret-width: 0.3em;

// Transitions

$transition-base-time: 0.15s;
$transition-base: all $transition-base-time ease;
$transition-fade: opacity $transition-base-time linear;
$transition-collapse: height $transition-base-time * 2 ease;
$transition-cubic-bezier: all $transition-base-time
  cubic-bezier(0.68, -0.55, 0.265, 1.55);
$transition-bg: background-color $transition-base-time * 2 linear;
$transition-ease-in-out: all $transition-base-time ease-in-out;

// Fonts

// Font icons

$icon-font-family: NucleoIcons, sans-serif;

// Fonts

$font-family-sans-serif: Open Sans, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-xl: ($font-size-base * 1.5);
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);
$font-size-xs: ($font-size-base * 0.75);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-extra-bold: 700;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;
$shape-height-base: 1.5;

$h1-font-size: $font-size-base * 1.625;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.0625;
$h4-font-size: $font-size-base * 0.9375;
$h5-font-size: $font-size-base * 0.8125;
$h6-font-size: $font-size-base * 0.625;

$headings-margin-bottom: calc($spacer / 2);
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.5;
$headings-color: $gray-800;

$heading-letter-spacing: 0.025em;
$heading-font-size: 0.95rem;
$heading-text-transform: uppercase;
$heading-font-weight: $headings-font-weight;

$heading-title-letter-spacing: 0.025em;
$heading-title-font-size: 1.375rem;
$heading-title-font-weight: $font-weight-bold;
$heading-title-text-transform: uppercase;

$heading-section-letter-spacing: 0.025em;
$heading-section-font-size: 1.375rem;
$heading-section-font-weight: $font-weight-bold;
$heading-section-text-transform: uppercase;

$display1-size: 3.3rem;
$display2-size: 2.75rem;
$display3-size: 2.1875rem;
$display4-size: 1.6275rem;

$display1-weight: $font-weight-bold;
$display2-weight: $font-weight-bold;
$display3-weight: $font-weight-bold;
$display4-weight: $font-weight-bold;
$display-line-height: $headings-line-height;

$paragraph-font-size: 1rem;
$paragraph-font-weight: 300;
$paragraph-line-height: 1.7;

$lead-font-size: ($paragraph-font-size * 1.25);
$lead-font-weight: 300;

$small-font-size: 80%;

$text-muted: $gray-600;

$blockquote-small-color: $gray-600;
$blockquote-font-size: ($font-size-base * 1.25);

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;

$mark-padding: 0.2em;

$dt-font-weight: $font-weight-bold;

$list-inline-padding: 0.5rem;

$mark-bg: #fcf8e3;

$hr-margin-y: $spacer * 2;

// Icons

$icon-size: 3rem;
$icon-size-xl: 5rem;
$icon-size-lg: 4rem;
$icon-size-sm: 2rem;
$icon-size-xs: 1.25rem;

// Tables

$table-cell-padding: 1rem;
$table-cell-padding-sm: 0.5rem;

$table-bg: transparent;
$table-accent-bg: rgba($gray-100, 0.3);
$table-hover-bg: $gray-100;
$table-active-bg: $table-hover-bg;

$table-border-width: $border-width;
$table-border-color: $gray-200;

$table-head-spacer-y: 0.75rem;
$table-head-spacer-x: 1rem;
$table-head-font-size: 0.65rem;
$table-head-font-weight: $font-weight-bold;
$table-head-text-transform: uppercase;
$table-head-letter-spacing: 1px;
$table-head-bg: $gray-100;
$table-head-color: $gray-600;
$table-action-color: $gray-500;

$table-body-font-size: 0.8125rem;

$table-dark-bg: theme-color("default");
$table-dark-accent-bg: rgba($white, 0.05);
$table-dark-hover-bg: rgba($white, 0.075);
$table-dark-border-color: lighten(theme-color("default"), 7%);
$table-dark-color: $body-bg;

$table-dark-head-bg: lighten(theme-color("default"), 4%);
$table-dark-head-color: lighten(theme-color("default"), 35%);
$table-dark-action-color: lighten(theme-color("default"), 35%);

// Buttons + Forms

$input-btn-padding-y: 0.625rem;
$input-btn-padding-x: 0.75rem;
$input-btn-line-height: $shape-height-base;

$input-btn-focus-width: 0;
$input-btn-focus-color: rgba($component-active-bg, 1);
$input-btn-focus-box-shadow: none;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-line-height-sm: $shape-height-sm;

$input-btn-padding-y-lg: 0.875rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-line-height-lg: $shape-height-lg;

$input-btn-border-width: 1px;

$input-btn-font-size-sm: 0.75rem;
$input-btn-font-size: 0.875rem;
$input-btn-font-size-lg: 0.875rem;

// Forms

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-border-radius: $border-radius-sm;
$input-border-radius-xl: $border-radius-xl;
$input-border-radius-lg: $border-radius-lg;
$input-border-radius-sm: $border-radius-sm;

$input-bg: $white;
$input-disabled-bg: $gray-200;

$input-muted-bg: #f7fafe;
$input-focus-muted-bg: lighten($input-muted-bg, 1%);

$input-color: $gray-600;
$input-border-color: $gray-300;
$input-border-width: $input-btn-border-width;
$input-box-shadow: 0 3px 2px rgba($gray-200, 0.05);

$input-focus-bg: $white;
$input-focus-border-color: $primary;
$input-focus-color: $input-color;
$input-focus-width: 0;
$input-focus-box-shadow: 0 3px 9px rgba(50, 50, 9, 0),
  3px 4px 8px rgba($primary, 0.1);

$input-placeholder-color: $gray-500;
$input-focus-placeholder-color: $gray-500;

$input-height-border: $input-border-width * 2;

$input-transition: $transition-cubic-bezier;

$input-alternative-box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15),
  0 1px 0 rgba(0, 0, 0, 0.02);
$input-focus-alternative-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11),
  0 1px 3px rgba(0, 0, 0, 0.08);

// Form group

$form-group-margin-bottom: 1.5rem;

// Form validation

$form-feedback-valid-bg: lighten($success, 15%);
$form-feedback-valid-color: theme-color("success");
$form-feedback-invalid-bg: lighten($warning, 15%);
$form-feedback-invalid-color: theme-color("warning");

// Input groups

$input-group-addon-color: $input-placeholder-color;
$input-group-addon-bg: $input-bg;
$input-group-addon-border-color: $input-border-color;

$input-group-addon-focus-color: $input-focus-color;
$input-group-addon-focus-bg: $input-focus-bg;
$input-group-addon-focus-border-color: $input-focus-border-color;

// Custom forms

$custom-control-gutter: 1.75rem;
$custom-control-spacer-x: 1rem;
$custom-control-indicator-size: 1rem;

$custom-control-indicator-bg: $input-bg;
$custom-control-indicator-border-width: 1px;
$custom-control-indicator-border-color: $input-border-color;
$custom-control-indicator-box-shadow: $input-box-shadow;

$custom-control-indicator-focus-box-shadow: $custom-control-indicator-box-shadow;

$custom-control-indicator-hover-color: $component-hover-color;
$custom-control-indicator-hover-bg: $component-hover-bg;
$custom-control-indicator-hover-border-color: $component-hover-border-color;

$custom-control-indicator-active-color: $component-active-color;
$custom-control-indicator-active-bg: $component-active-bg;
$custom-control-indicator-active-border-color: $component-active-border-color;
$custom-control-indicator-active-box-shadow: $custom-control-indicator-box-shadow;

$custom-control-indicator-checked-color: $component-active-color;
$custom-control-indicator-checked-bg: $component-active-bg;
$custom-control-indicator-checked-border-color: $component-active-border-color;
$custom-control-indicator-checked-box-shadow: $custom-control-indicator-box-shadow;
$custom-control-indicator-checked-disabled-bg: rgba(
  theme-color("primary"),
  0.5
);

$custom-control-indicator-disabled-bg: $gray-200;
$custom-control-label-disabled-color: $gray-600;

$custom-checkbox-indicator-border-radius: $border-radius-sm;
//$custom-checkbox-indicator-icon-checked:      str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23");

// Custom toggle

$custom-toggle-width: 52px;
$custom-toggle-slider-bg: $gray-200;
$custom-toggle-checked-bg: theme-color("primary");
$custom-toggle-border-color: $gray-400;

// Buttons

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x + 0.5;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: 600;
$btn-letter-spacing: 0.025em;
$btn-text-transform: none;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1),
  0 3px 6px rgba(0, 0, 0, 0.08);
$btn-focus-box-shadow: $btn-hover-box-shadow;
$btn-focus-width: $input-btn-focus-width;
$btn-active-box-shadow: none;
$btn-hover-translate-y: -1px;

// Allows for customizing button radius independently from global border radius

$btn-border-radius: $input-border-radius;
$btn-border-radius-xl: $input-border-radius-xl;
$btn-border-radius-lg: $input-border-radius-lg;
$btn-border-radius-sm: $input-border-radius;

// Dropdown

$dropdown-bg: $white;
$dropdown-border-width: 0;
$dropdown-border-color: rgba($black, 0.15);
$dropdown-border-radius: $border-radius-lg;
$dropdown-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1),
  0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);

$dropdown-link-color: $gray-900;
$dropdown-link-bg: $white;

$dropdown-link-hover-color: darken($gray-900, 5%);
$dropdown-link-hover-bg: $gray-100;

$dropdown-link-active-color: $dropdown-link-hover-color;
$dropdown-link-active-bg: transparent;

$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1rem;

// Map

$map-height: 500px;

// Navs

$nav-link-padding-y: 0.25rem;
$nav-link-padding-x: 0.75rem;
$nav-link-color: $gray-700;
$nav-link-hover-color: theme-color("primary");
$nav-link-disabled-color: $gray-600;

$nav-pills-padding-y: 0.75rem;
$nav-pills-padding-x: 1rem;

$nav-pills-space-x: 1rem;

$nav-pills-bg: $white;
$nav-pills-border-width: 1px;
$nav-pills-border-color: theme-color("primary");
$nav-pills-border-radius: $border-radius;

$nav-pills-link-color: theme-color("primary");
$nav-pills-link-hover-color: darken(theme-color("primary"), 5%);
$nav-pills-link-active-color: color-yiq(theme-color("primary"));
$nav-pills-link-active-bg: theme-color("primary");
$nav-pills-box-shadow: $btn-box-shadow;

// Navbar

$navbar-padding-y: 1rem;
$navbar-padding-x: 1rem;

$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: 1rem;

$navbar-nav-link-font-size: 0.875rem;
$navbar-nav-link-font-weight: 500;
$navbar-nav-link-text-transform: normal;
$navbar-nav-link-letter-spacing: 0;
$navbar-nav-link-border-radius: $border-radius;

$navbar-dark-bg: transparent;
$navbar-dark-hover-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-active-bg: rgba(255, 255, 255, 0.1);
$navbar-dark-border-color: rgba(255, 255, 255, 0.1);
$navbar-dark-color: rgba($white, 0.95);
$navbar-dark-hover-color: rgba($white, 0.65);
$navbar-dark-active-color: rgba($white, 0.65);
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-border-color: transparent;

$navbar-light-bg: $gray-100;
$navbar-light-hover-bg: darken($gray-100, 5%);
$navbar-light-active-bg: darken($gray-100, 5%);
$navbar-light-border-color: rgba($black, 0.05);
$navbar-light-color: rgba($black, 0.6);
$navbar-light-hover-color: rgba($black, 0.7);
$navbar-light-active-color: rgba($black, 0.9);
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-border-color: transparent;

// Vertical navbar

$navbar-vertical-box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
$navbar-vertical-border-color: rgba($black, 0.05);
$navbar-vertical-width: 62px;
$navbar-vertical-open-width: 250px;
$navbar-vertical-padding-x: 1.5rem;
$navbar-vertical-nav-link-padding-x: 1.5rem;
$navbar-vertical-nav-link-padding-y: 0.675rem;

$navbar-icon-min-width: 2rem;

$navbar-breadcrumb-padding-y: $nav-link-padding-y;
$navbar-breadcrumb-padding-x: 0;

$navbar-light-bg: $white;
$navbar-light-border-color: $border-color;

// Navbar search

$navbar-search-width: 250px;
$navbar-search-focus-width: 380px;
$navbar-search-bg: transparent;
$navbar-search-transition: $transition-cubic-bezier;

$navbar-search-border-radius: 2rem;
$navbar-search-border-width: 0;

$navbar-search-dark-bg: rgba($default, 0.8);
$navbar-search-dark-focus-bg: rgba($default, 0.9);
$navbar-search-dark-border-color: rgba(255, 255, 255, 0.6);
$navbar-search-dark-color: rgba(255, 255, 255, 0.6);
$navbar-search-dark-focus-border-color: rgba(255, 255, 255, 0.9);
$navbar-search-dark-focus-color: rgba(255, 255, 255, 0.9);

$navbar-search-light-bg: rgba(255, 255, 255, 0.9);
$navbar-search-light-focus-bg: rgba(255, 255, 255, 1);
$navbar-search-light-border-color: rgba(0, 0, 0, 0.6);
$navbar-search-light-color: rgba(0, 0, 0, 0.6);
$navbar-search-light-focus-border-color: rgba(0, 0, 0, 0.9);
$navbar-search-light-focus-color: rgba(0, 0, 0, 0.9);

// Content

$main-content-padding-y: 40px;
$main-content-padding-x: 15px;

// Alerts

$alert-padding-y: 1rem;
$alert-padding-x: 1.5rem;
$alert-border-radius: $border-radius;

$alert-bg-level: -2;
$alert-border-level: -2;
$alert-color-level: 0;

// List group

$list-group-bg: $white;
$list-group-border-color: $border-color; //rgba($black, .125);
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: 1rem;
$list-group-item-padding-x: 1rem;

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $list-group-action-color;
$list-group-action-active-bg: $gray-200;

// Close

$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-bg: transparent;
$close-hover-bg: transparent;
$close-color: rgba(0, 0, 0, 0.6);
$close-hover-color: rgba(0, 0, 0, 0.9);
$close-text-shadow: none;

// Popovers

$popover-font-size: $font-size-sm;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: 1px;
$popover-border-color: rgba($black, 0.05);
$popover-border-radius: $border-radius-lg;
$popover-box-shadow: 0px 0.5rem 2rem 0px rgba($black, 0.2);

$popover-header-bg: $popover-bg;
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: 0.95rem;

$popover-body-color: $body-color;
$popover-body-padding-y: $popover-header-padding-y;
$popover-body-padding-x: $popover-header-padding-x;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: transparent;

// Badges

$badge-font-size: 66%;
$badge-font-weight: $font-weight-bold;
$badge-padding-y: 0.35rem;
$badge-padding-x: 0.375rem;
$badge-border-radius: $border-radius;
$badge-text-transfom: uppercase;

$badge-pill-padding-x: 0.875em;
$badge-pill-border-radius: 10rem;

$badge-circle-size: 2rem;

// Pagination

$pagination-color: $gray-600;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $gray-300;

$pagination-hover-color: $gray-600;
$pagination-hover-bg: $gray-300;
$pagination-hover-border-color: $gray-300;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;
$pagination-active-box-shadow: $btn-hover-box-shadow;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;

// Cards

$card-spacer-y: 1.25rem;
$card-spacer-x: 1.5rem;
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, 0.05);
$card-inner-border-radius: calc(
  #{$card-border-radius} - #{$card-border-width}
);
$card-cap-bg: $white;
$card-bg: $white;
$card-box-shadow: $box-shadow;

$card-img-overlay-padding: 1.25rem;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;

// Tooltips

$tooltip-font-size: $font-size-sm;

// Modals

$modal-inner-padding: 1.5rem;

$modal-lg: 800px;
$modal-md: 500px;
$modal-sm: 380px;

$modal-title-font-size: $h3-font-size;
$modal-title-line-height: 1.1;

$modal-content-bg: $white;
$modal-content-border-color: rgba($black, 0.2);
$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius-lg;
$modal-content-box-shadow-xs: 0 15px 35px rgba(50, 50, 93, 0.2),
  0 5px 15px rgba(0, 0, 0, 0.17);
$modal-content-box-shadow-sm-up: 0 15px 35px rgba(50, 50, 93, 0.2),
  0 5px 15px rgba(0, 0, 0, 0.17);

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.16;
$modal-header-border-color: $gray-200;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding: 1.25rem;

// Footer

$footer-padding-y: 30px;
$footer-padding-x: 0;

$footer-link-font-size: 0.85rem;
$footer-bg: $body-bg;
$footer-color: $gray-600;
$footer-link-color: $gray-600;
$footer-link-hover-color: $gray-700;
$footer-heading-color: $gray-600;
$footer-heading-font-size: $font-size-sm;

//
// Plugins
//

// Datepicker

$datepicker-border-radius: $card-border-radius;
$datepicker-dropdown-padding: 20px 22px;

$datepicker-cell-transition: $transition-base;
$datepicker-cell-hover-background: lighten($gray-400, 55%);
$datepicker-cell-border-radius: 50%;
$datepicker-cell-width: 36px;
$datepicker-cell-height: 36px;

$datepicker-disabled-cell-color: $gray-300;
$datepicker-disabled-old-new-color: $gray-500;

$datepicker-header-cell-border-radius: $border-radius;

$datepicker-active-color: $white;
$datepicker-active-background: theme-color("primary");
$datepicker-active-box-shadow: none;

$datepicker-range-background: theme-color("primary");
$datepicker-range-cell-focused-background: darken(
  $datepicker-range-background,
  5%
);
$datepicker-range-color: $white;
$datepicker-range-highlighted-bg: $gray-200;

$datepicker-dropdown-border: lighten($gray-400, 40%);
$datepicker-dropdown-bg: $white;
$datepicker-highlighted-bg: $datepicker-active-background;

// NoUISlider

$noui-target-bg: #eceeef;
$noui-target-thickness: 5px;
$noui-target-border-radius: 5px;
$noui-target-border-color: 0;
$noui-target-box-shadow: inset 0 1px 2px rgba(90, 97, 105, 0.1);

$noui-slider-connect-bg: $primary;
$noui-slider-connect-disabled-bg: #b2b2b2;

$noui-handle-width: 15px;
$noui-handle-bg: theme-color("primary");
$noui-handle-border: 0;
$noui-handle-border-radius: 100%;

$noui-origin-border-radius: 2px;

//
// PRO components and plugins
// Need more for your project? Check out the PRO version
// URL:
//

// Breadcrumb

$breadcrumb-padding-y: 0.5rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 1rem;
$breadcrumb-bg: $white;
$breadcrumb-divider-color: $default;
$breadcrumb-active-color: $gray-900;
$breadcrumb-divider: quote("/");
$breadcrumb-border-radius: $border-radius;

$breadcrumb-dark-bg: $default;
$breadcrumb-dark-color: $white;
$breadcrumb-dark-hover-color: $gray-300;
$breadcrumb-dark-active-color: $gray-300;
$breadcrumb-dark-divider-color: $white;

// Timeline

$timeline-axis-width: 2px;
$timeline-axis-color: $border-color;

$timeline-step-bg: $white;
$timeline-step-border-width: 2px;
$timeline-step-border-color: $timeline-axis-color;

//
// Plugins
//

// Chart

$chart-height: 350px;
$chart-height-sm: 230px;

$chart-legend-margin-top: 2.5rem;
$chart-legend-font-size: $font-size-sm;
$chart-legend-color: $text-muted;
$chart-legend-height: $chart-legend-margin-top + $chart-legend-font-size *
  $line-height-base;

// Sweet alert

$swal2-width: 30em;
$swal2-padding: 1.5rem;
$swal2-title-font-size: 1.5rem;
$swal2-content-font-size: 0.875rem;

// Tags

$tags-input-border-width: 0;
$tags-input-border-color: transparent;
$tags-input-color: $gray-600;

$tag-bg: theme-color("default");
$tag-color: color-yiq(theme-color("default"));
$tag-close-color: color-yiq(theme-color("default"));
$tag-box-shadow: 0 1px 2px rgba(68, 68, 68, 0.25);

// Import Bootstrap variable defaults

@import "../bootstrap/variables";

