// Bootstrap functions
@import "bootstrap/functions";
@import "bootstrap/mixins";

// Argon functions
@import "custom/functions";
@import "custom/variables";
@import "bootstrap/variables";

// Argon mixins
@import "custom/mixins";

// Bootstrap components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
@import "bootstrap/badge";
@import "bootstrap/jumbotron";
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/utilities";
@import "bootstrap/print";

// Argon components
@import "custom/components";
@import "custom/utilities";
@import "custom/vendors";

// Angular changes
@import "angular/dropdowns";
@import "angular/navbar-vertical";
@import "angular/progressbar";
@import "angular/plugin-ngx-chips";
@import "angular/ngx-toastr";
@import "angular/tooltips";
@import "angular/plugin-ngx-datatable";
@import "angular/navbar-vertical";
@import "angular/vector-map";
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import '~viewerjs/dist/viewer.css';

[data-theme="light"] {
  --white: #FFFFFF;
  --black: #313131;
  --gray-500: #696969;
  --git-field-bg: #FFFFFF;
  --git-field-color: #313131;
  --git-datatable-header: #F1F4F9;
  --git-datatable-footer: #FFFFFF;
  --git-datatable-footer-color: #787878;
  --git-datatable-body-even: #FFFFFF;
  --git-datatable-body-odd: #F4F8FF;
  --git-datatable-active-row: rgba(61, 192, 240, 0.3);
  --git-card-bg: #FFFFFF;
  --git-label-text-muted: #8898aa;
  --git-dashboard-card-bg-1: #FFF;
  --git-dashboard-card-bg-2: rgba(255, 255, 255, 0.88);
  --git-color-angle: #1F2667;
  --git-field-label: #313131;
  --git-field-multiple-file-data-label: blue;
  --git-field-label-bg: #FFFFFF;
  --git-field-toggle-content: #716f6f;
  --git-field-label-hover-disabled: #696969;
  --git-field-label-bg-hover-disabled: #E9ECEF;
  --git-quill-editor-tool-list-field: #ffffff;
  --git-quill-editor-field: #ffffff;
  --git-retry-error-bg: #f5f5f5;
  --git-home-task-wrapper: #EBF9FF;
  --git-hierarchy-bg-first-color: transparent;
  --git-hierarchy-bg-second-color: transparent;
  --git-hierarchy-box-shadow: lightgray;
  --git-btn-hover-hierarchy: #5e72e4;
  --git-talent-matrix-bg-wrapper-blue: rgba(243, 247, 255, 1);
  --git-talent-matrix-bg-wrapper-purple: rgba(245, 243, 255, 1);
  --git-talent-matrix-bg-wrapper-pink: rgba(253, 243, 255, 1);
  --git-talent-matrix-bg-wrapper-yellow: rgba(255, 246, 243, 1);
  --git-calendar-sat-sun: rgba(242, 242, 242, 1);
  --git-skeleton: lightgray;
  --git-skeleton-body: lightgray;
  --git-webkit-scrollbar: rgb(229, 229, 229);
  --git-webkit-scrollbar-thumb: rgb(180, 180, 180);
  --git-webkit-scrollbar-thumb-hover: rgb(154, 154, 154);
  --git-disabled-form-content-wrapper: rgba(0, 0, 0, 0.2);
  --git-body-bg: #f5f5f5;
  --git-body-color: #696969;
  --git-sidebar-bg-primary: #ffffffbf;
  --git-sidebar-bg-secondary: #e7e6e6;
  --git-menu-primary: #313131;
  --git-menu-secondary: #787878;
  --git-menu-category: #8392eb;
  transition: color 0.7s, background-color 0.7s;
}

[data-theme="dark"] {
  --white: #313131;
  --black: #FFFFFF;
  --gray-500: #FFFFFF;
  --git-field-bg: #404040;
  --git-field-color: #FFFFFF;
  --git-datatable-header: #313131;
  --git-datatable-footer: #272727;
  --git-datatable-footer-color: #FFFFFF;
  --git-datatable-body-even: #313131;
  --git-datatable-body-odd: #282828;
  --git-datatable-active-row: rgba(61, 192, 240, 0.3);
  --git-card-bg: #272727;
  --git-shadowbox-color: #0000000d;
  --git-label-text-muted: #FFFFFF;
  --git-dashboard-card-bg-1: #313131;
  --git-dashboard-card-bg-2: rgba(49, 49, 49, 0.7);
  --git-color-angle: rgb(34, 46, 153);
  --git-field-label: #ffffff;
  --git-field-multiple-file-data-label: #ffffff;
  --git-field-label-bg: #272727;
  --git-field-toggle-content: #ffffff;
  --git-field-label-hover-disabled: #ffffff;
  --git-field-label-bg-hover-disabled: #616266;
  --git-quill-editor-tool-list-field: #D3D3D3;
  --git-quill-editor-field: #2e3a49;
  --git-retry-error-bg: #616266;
  --git-home-task-wrapper: rgba(54, 54, 54, 1);
  --git-hierarchy-bg-first-color: #008B8B;
  --git-hierarchy-bg-second-color: #E0FFFF;
  --git-hierarchy-box-shadow: #00FFFF;
  --git-btn-hover-hierarchy: #E4E6EB;
  --git-talent-matrix-bg-wrapper-blue: rgba(36, 121, 249, 0.3);
  --git-talent-matrix-bg-wrapper-purple: rgba(144, 97, 239, 0.3);
  --git-talent-matrix-bg-wrapper-pink: rgba(231, 93, 205, 0.3);
  --git-talent-matrix-bg-wrapper-yellow: rgba(241, 178, 37, 0.3);
  --git-calendar-sat-sun: rgba(255,255,255, 0.1);
  --git-skeleton: rgba(255,255,255,0.1);
  --git-skeleton-body: #555659;
  --git-webkit-scrollbar: #3A3B3C;
  --git-webkit-scrollbar-thumb: #646669;
  --git-webkit-scrollbar-thumb-hover: #8b8c91;
  --git-disabled-form-content-wrapper: rgba(255, 255, 255, 0.2);
  --git-body-bg: #313131;
  --git-shadowbox-color: rgba(255, 255, 255, 0.05);
  --git-body-color: #FFFFFF;
  --git-sidebar-bg-primary: #00000080;
  --git-sidebar-bg-secondary: #3A3B3F;
  --git-menu-primary: #FFFFFF;
  --git-menu-secondary: #EAEAEA;
  --git-menu-category: #ff7448;
  transition: color 0.7s, background-color 0.7s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
