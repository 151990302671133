@use "sass:math";

.main-content {
    position: relative;

    &:has(.modal-chatbot.show) {
        width: calc(70% + 0.5rem);
    }

    // Navbar
    .navbar-top {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    // Container
    .container-fluid {
        @include media-breakpoint-up(md) {
            padding-left: ($main-content-padding-x + math.div($grid-gutter-width, 2)) !important;
            padding-right: ($main-content-padding-x + math.div($grid-gutter-width, 2)) !important;
        }
    }
}

$breakpoint-tablet: 758px;
@media only screen and (max-width: $breakpoint-tablet) {
    .main-content-login {
        display: flex;
        justify-content: center;
        align-items: center;
        .web-people-circle,
        .bg-style,
        .shadow {
            display: none;
        }
        .sun-morning,
        .sun-sunset {
            width: calc(378px / 2) !important;
            height: calc(378px / 2) !important;
            top: calc((378px / 2) / 2 * -1) !important;
            right: calc((378px / 2) / 2 * -1) !important;
        }
        .moon {
            svg {
                width: calc(267px / 2.5) !important;
                height: calc(352px / 2.5) !important;
            }
        }
        .container-login-form {
            width: 100% !important;
            .header-sc-logo {
                .subtitle-label {
                    font-size: 16px !important;
                }
            }
            .content-login-form {
                width: 90% !important;
            }
        }
    }
}

.main-content-login {
    font-family: "Nunito";
    position: relative;
    background: $bg-linier-primary;
    height: 100vh;
    overflow: hidden;
    &.night-mode {
        background: $bg-linier-darkmode !important;
    }
    .sun-morning {
        height: 378px;
        width: 378px;
        border-radius: 50%;
        background: $sun-morning-color;
        position: absolute;
        top: calc(378px / 2 * -1);
        right: calc(378px / 2 * -1);
    }
    .sun-sunset {
        height: 378px;
        width: 378px;
        border-radius: 50%;
        background: $sun-sunset-color;
        position: absolute;
        top: calc(378px / 2 * -1);
        right: calc(378px / 2 * -1);
    }
    .moon {
        position: absolute;
        top: 0;
        right: 0;
        svg {
            width: calc(267px / 2);
            height: calc(352px / 2);
            * {
                width: 100%;
                height: 100%;
            }
        }
    }

    .cloud {
        background: url("/assets/img/brand/cloud.png");
        height: 15%;
        width: 35%;
        position: absolute;
        top: 15px;
        left: 20px;
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .star {
        background: url("/assets/img/brand/star.png");
        height: 15%;
        width: 35%;
        position: absolute;
        top: 15px;
        left: 20px;
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .bg-style {
        position: absolute;
        bottom: 0%;
        background: url("/assets/img/brand/bg-style.png");
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        height: 86%;
        width: 50%;
    }

    .web-people-circle {
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        background-image: url("/assets/img/brand/people-login.png");
        height: 45%;
        width: 45%;
        position: absolute;
        z-index: 2;
        left: 5%;
        top: 23%;
    }
    .shadow {
        border-radius: 50%;
        background: rgba(84, 84, 84, 0.66);
        filter: blur(17.5px);
        width: 295px;
        height: 15px;
        flex-shrink: 0;
        position: absolute;
        top: 68%;
        left: 10%;
    }
    .container-login-form {
        position: absolute;
        width: 50%;
        height: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        right: 0;
        .header-sc-logo {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
                width: 50%;
                height: 75%;
            }
            .subtitle-label {
                color: $white;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
        .content-login-form {
            display: flex;
            flex-direction: column;
            width: 499px;
            height: 469px;
            flex-shrink: 0;
            padding: 42px;
            border-radius: 20px;
            color: $white;
            background: rgba(255, 255, 255, 0.4);
            box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(7.5px);
            margin: 15px;
            .title-form {
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            .subtitle-form {
                font-size: 26px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .form-login {
                margin-top: 1.5rem;
                .form-group {
                    .inputGroup {
                        position: relative;
                        input {
                            position: relative;
                            width: 100%;
                            padding: 1.2rem 4rem 1.2rem 2rem;
                            padding-left: 3.5rem;
                            background: none;
                            border: solid 1.5px $primary;
                            border-radius: 20px !important;
                            color: #9e9e9e;
                            font-size: 1rem;
                            background-color: $white;
                            outline: 0;
                            transition: 0.5s;
                        }
                        label {
                            position: absolute;
                            pointer-events: none;
                            left: 1rem;
                            top: 0.8rem;
                            white-space: nowrap;
                            overflow: hidden;
                            margin-bottom: 0;
                            line-height: 2.5rem;
                            padding-left: 3rem;
                            font-size: 1rem;
                            color: #9e9e9e;
                            transition: 500ms cubic-bezier(0.4, 0, 0.2, 1);
                        }
                        .input-icon-left {
                            font-size: 1.5rem;
                            position: absolute;
                            left: 0;
                            height: 100%;
                            color: #adb5bd;
                            display: flex;
                            align-items: center;
                            padding: 0.625rem 0.75rem;
                            margin-bottom: 0;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #adb5bd;
                            text-align: center;
                            white-space: nowrap;
                            border-radius: 0.25rem;
                            z-index: 1;
                        }
                        .input-icon-right {
                            font-size: 1.5rem;
                            position: absolute;
                            right: 0;
                            height: 100%;
                            color: #adb5bd;
                            display: flex;
                            align-items: center;
                            padding: 0.625rem 0.75rem;
                            margin-bottom: 0;
                            font-weight: 400;
                            line-height: 1.5;
                            color: #adb5bd;
                            text-align: center;
                            white-space: nowrap;
                            border-radius: 0.25rem;
                            z-index: 1;
                        }
                    }
                }

                .inputGroup :is(input:focus, input:valid) ~ label {
                    top: -6px;
                    left: 0.5rem;
                    font-size: 0.875rem;
                    color: #9e9e9e;
                    transform: none;
                    background-color: transparent;
                }
                .inputGroup :is(input:focus, input:valid) {
                    padding-bottom: 0.5rem;

                    input:focus {
                        border: 1px solid #3788d8;
                    }
                }

                .inputGroup :is(input:focus) ~ label {
                    color: #3788d8;
                }
            }
        }
        .container-remember-me {
            display: flex;
            cursor: pointer;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;
            .custom-control-input {
                display: none;
                &:checked ~ svg .path {
                    stroke-dasharray: 70.5096664428711 9999999;
                    stroke-dashoffset: -262.2723388671875;
                    stroke: $git-success;
                    stroke-width: 20 !important;
                }
            }
            svg {
                overflow: visible;
                path {
                    fill: none;
                    stroke: white;
                    stroke-width: 6;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    transition: stroke-dasharray 0.5s ease, stroke-dashoffset 0.5s ease;
                    stroke-dasharray: 241 9999999;
                    stroke-dashoffset: 0;
                }
            }
        }
    }
}

// Navbar
.navbar-top {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.container-login {
    border-top-left-radius: 10%;
    border-bottom-left-radius: 10%;
    background: rgba(247, 250, 252, 0.92);
    width: 100%;
    height: 100%;
}

.form-sign-in {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 65%;
    margin: 0 auto;
}

// Container
.container-fluid {
    @include media-breakpoint-up(md) {
        padding-left: ($main-content-padding-x + math.div($grid-gutter-width, 2)) !important;
        padding-right: ($main-content-padding-x + math.div($grid-gutter-width, 2)) !important;
    }
}
