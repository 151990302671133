// for dark mode
@use "@angular/material" as mat;

@include mat.core();

$angular-primary: mat.define-palette(mat.$teal-palette, 500, 100, 900);
$angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$angular-warn: mat.define-palette(mat.$red-palette);

$angular-default-theme: mat.define-light-theme(
    (
        color: (
            primary: $angular-primary,
            accent: $angular-accent,
            warn: $angular-warn,
        ),
    )
);

@include mat.all-component-themes($angular-default-theme);

/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@import "~@angular/cdk/overlay-prebuilt.css";

html,
body {
    background-color: var(--git-body-bg) !important;
    color: var(--git-body-color) !important;

    ::-webkit-scrollbar {
        background: var(--git-webkit-scrollbar);
        width: 12px;
        height: 12px;
    }
    ::-webkit-scrollbar-thumb {
        background: var(--git-webkit-scrollbar-thumb);
    }
    ::-webkit-scrollbar-thumb:hover {
        background: var(--git-webkit-scrollbar-thumb-hover);
    }

    //Specific style for manage evaluation form
    &:has(.manage-evaluation-form-class) {
        .nav-git-custom {
            z-index: 2 !important;
        }
        div:where(.swal2-container) {
            z-index: 3 !important;
        }
    }

    .dashboard-certification-datatable {
        ngx-datatable.ngx-datatable.material {
            .datatable-header {
                .datatable-header-cell {
                    background-color: var(--git-field-label-bg) !important;

                    .sort-btn {
                        color: #3e8bff !important;
                    }

                    .datatable-header-cell-template-wrap {
                        color: var(--git-field-label) !important;
                        font-weight: 600 !important;
                    }
                }
            }

            .datatable-body {
                height: 305px !important;
                width: 100% !important;
                overflow-y: auto;
                background-color: var(--git-field-label-bg) !important;

                .datatable-body-row {
                    background: var(--git-field-label-bg) !important;

                    .datatable-row-group {
                        background: var(--git-field-label-bg) !important;

                        .datatable-body-cell {
                            height: 100% !important;
                        }
                    }
                }

                span {
                    color: var(--git-field-label) !important;
                }

                .text-bold {
                    font-weight: 600;
                }

                .datatable-scroll {
                    width: inherit !important;
                }
            }
        }
    }
}

body {
    height: 100%;

    &:has(.modal-chatbot.show) {
        overflow-y: auto !important;

        .navbar-custom-git {
            right: calc(30% + 25px);
        }
    }

    .modal-applicant-selection {
        .mat-expansion-indicator::after {
            color: var(--git-field-label) !important;
        }
    }

    .preview-wrapper {
        height: 70vh;
        width: 100%;
        overflow-y: auto;

        .docx-preview-wrapper {
            background: #f5f5f5 !important;

            // .docx-preview {} //To edit style in the document if needed
        }
    }

    .modal-chatbot {
        .ng-select-disabled {
            .ng-select-container {
                opacity: 0.7;
            }
        }

        .ng-select-container {
            background: transparent !important;
            border: 1px solid white !important;
            color: white !important;
            border-radius: 20px;
        }

        .ng-dropdown-panel {
            border-radius: 0 0 20px 20px;

            .ng-dropdown-panel-items {
                border-radius: 0 0 20px 20px;

                .ng-option:last-child {
                    border-radius: 0 0 20px 20px;
                }
            }
        }

        .ng-clear-wrapper {
            color: white !important;
            margin-top: 4px;
        }

        .ng-select-opened {
            .ng-arrow-wrapper {
                .ng-arrow {
                    border-color: transparent transparent white !important;
                }
            }
        }

        .ng-arrow-wrapper {
            .ng-arrow {
                border-color: white transparent transparent !important;
            }
        }
    }
}

.card.skeleton {
    // background:#3a3b3d !important;
    background: var(--git-skeleton-body) !important;
    background-color: var(--git-skeleton-body) !important;

    &.calendar .card-header {
        background: var(--git-field-label-bg) !important;
    }

    .skeleton-row {
        background-color: var(--git-skeleton-body) !important;
    }
    .card-body {
        background-color: var(--git-skeleton-body) !important;
    }
    .card-header.skeleton-card {
        background-color: var(--git-skeleton-body) !important;
    }
}

.btn-custom {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;

    display: inline-block;

    padding: 0.625rem 1.25rem;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;

    border: 1px solid transparent;
    border-radius: 0.375rem;
}
@media screen and (prefers-reduced-motion: reduce) {
    .btn-custom {
        transition: none;
    }
}
.btn-custom:hover,
.btn-custom:focus {
    text-decoration: none;
}
.btn-custom:focus,
.btn-custom.focus {
    outline: 0;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}
.btn-custom.disabled,
.btn-custom:disabled {
    opacity: 0.65;
    box-shadow: none;
}
.btn-custom:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.btn-custom:not(:disabled):not(.disabled):active,
.btn-custom:not(:disabled):not(.disabled).active {
    box-shadow: none;
}
.btn-custom:not(:disabled):not(.disabled):active:focus,
.btn-custom:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08), none;
}

a.btn-custom.disabled,
fieldset:disabled a.btn-custom {
    pointer-events: none;
}

.btn-login {
    color: #fff;
    border-color: #0070d2;
    background-color: #0070d2;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}
.btn-login:hover {
    color: #fff;
    border-color: #0070d2;
    background-color: #0070d2;
}
.btn-login:focus,
.btn-login.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.btn-login.disabled,
.btn-login:disabled {
    color: #fff;
    border-color: #0070d2;
    background-color: #0070d2;
}
.btn-login:not(:disabled):not(.disabled):active,
.btn-login:not(:disabled):not(.disabled).active,
.show > .btn-login.dropdown-toggle {
    color: #fff;
    border-color: #0070d2;
    background-color: #324cdd;
}
.btn-login:not(:disabled):not(.disabled):active:focus,
.btn-login:not(:disabled):not(.disabled).active:focus,
.show > .btn-login.dropdown-toggle:focus {
    box-shadow: none, 0 0 0 0 rgba(94, 114, 228, 0.5);
}

.blockText {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.mat-accordion > .mat-expansion-panel-spacing,
.mat-accordion > *:not(.mat-expansion-panel) .mat-expansion-panel-spacing,
.mat-expansion-panel-content {
    background: var(--git-field-label-bg);
}

.mat-expansion-panel {
    background: var(--git-field-label-bg);
    color: rgba(0, 0, 0, 0.87);
    box-sizing: content-box;
    display: block;
    margin: 0;
    border-radius: 4px;
    overflow: hidden;
    transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0px rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 14%);
}

/* Material Expansion */
.mat-expansion-panel-header {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 400;
    height: 48px;
    padding: 0 24px;
    background: var(--git-field-label-bg);
}

.mat-expansion-panel-header-title {
    padding-top: 5px;
    h3 {
        color: var(--git-field-label);
    }
}

.mat-expansion-panel-header-description {
    color: rgba(0, 0, 0, 0.54);
    flex-basis: fit-content !important;
    flex-grow: 0 !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: var(--mat-tab-header-active-label-text-color) !important;
}

.mat-mdc-tab .mdc-tab__text-label {
    color: var(--git-field-label) !important;
}

.mat-mdc-tab-header-pagination-chevron {
    border-color: var(--git-field-label) !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background: rgba(0, 0, 0, 0.04);
}

.mat-expansion-panel-header.mat-expanded {
    height: 64px;
}

.mat-expansion-panel-header.mat-expanded + .mat-expansion-panel-content {
    padding: 15px 0 0;
}

tr.google-visualization-orgchart-connrow-medium {
    height: 6px;
    font-size: 25px;
}

.companyHierarchy tr.google-visualization-orgchart-connrow-medium {
    height: 6px;
    font-size: 35px;
}

.organizationHierarchy tr.google-visualization-orgchart-connrow-medium {
    height: 6px;
    font-size: 15px;
    position: relative;
}

.organizationScrollbar::-webkit-scrollbar {
    width: 5px;
}
.organizationScrollbar::-webkit-scrollbar-thumb {
    background-color: lightgrey;
}
.organizationScrollbar::-webkit-scrollbar-thumb:hover {
    background-color: grey;
}

/* .mat-progress-spinner circle, .mat-spinner circle {   
      stroke: #0CC20C;
  } */

/* FOR FULL CALENDAR  */

.fc-col-header-cell,
.fc-daygrid-day.fc-day {
    background: var(--git-field-label-bg);
}

.calendarShiftBackground {
    background: linear-gradient(89.7877943795121deg, rgba(49, 149, 242, 1) 1%, rgba(159, 209, 255, 1) 80%);
    background: -webkit-linear-gradient(0.212205620487907deg, rgba(49, 149, 242, 1) 1%, rgba(159, 209, 255, 1) 80%);
}
.calendarHolidayBackground {
    background: linear-gradient(90deg, rgba(251, 9, 9, 1) 0%, rgba(255, 128, 128, 1) 80%);
    background: -webkit-linear-gradient(0deg, rgba(251, 9, 9, 1) 0%, rgba(255, 128, 128, 1) 80%);
}
.calendarLeaveBackground {
    background: linear-gradient(90deg, rgba(255, 103, 157, 1) 0%, rgba(255, 204, 216, 1) 80%);
    background: -webkit-linear-gradient(0deg, rgba(255, 103, 157, 1) 0%, rgba(255, 204, 216, 1) 80%);
}
.calendarSickBackground {
    background: linear-gradient(89.9999999999998deg, rgba(202, 91, 255, 1) 0%, rgba(232, 188, 253, 1) 81%);
    background: -webkit-linear-gradient(1.70530256582424e-13deg, rgba(202, 91, 255, 1) 0%, rgba(232, 188, 253, 1) 81%);
}
.calendarPermissionBackground {
    background: linear-gradient(90deg, rgba(255, 146, 45, 1) 0%, rgba(255, 209, 167, 1) 80%);
    background: -webkit-linear-gradient(0deg, rgba(255, 146, 45, 1) 0%, rgba(255, 209, 167, 1) 80%);
}
.calendarActualBackground {
    background: linear-gradient(89.7877943795121deg, rgba(46, 196, 21, 1) 1%, rgba(131, 244, 92, 1) 80%);
    background: -webkit-linear-gradient(0.212205620487907deg, rgba(46, 196, 21, 1) 1%, rgba(131, 244, 92, 1) 80%);
}
.calendarActualListBackground {
    background: linear-gradient(90deg, rgba(143, 229, 124, 1) 0%, rgba(159, 238, 133, 1) 99%);
    background: -webkit-linear-gradient(0deg, rgba(143, 229, 124, 1) 0%, rgba(159, 238, 133, 1) 99%);
}

.calendarOvertimeBackground {
    background: linear-gradient(88.1123437921231deg, rgba(14, 181, 151, 1) 19%, rgba(30, 255, 215, 1) 99%);
    background: -webkit-linear-gradient(1.88765620787689deg, rgba(14, 181, 151, 1) 19%, rgba(30, 255, 215, 1) 80%);
}
.calendarOvertimeListBackground {
    background: linear-gradient(90deg, rgba(18, 193, 164, 1) 0%, rgba(109, 255, 229, 1) 99%);
    background: -webkit-linear-gradient(0deg, rgba(18, 193, 164, 1) 0%, rgba(109, 255, 229, 1) 99%);
}
.calendarHeaderBackground {
    background: linear-gradient(89.9185908767319deg, rgba(17, 113, 239, 1) 1%, rgba(137, 164, 251, 1) 100%);
    background: -webkit-linear-gradient(0.0814091232681449deg, rgba(17, 113, 239, 1) 1%, rgba(137, 164, 251, 1) 100%);
}

.calendarTimeOffBackground {
    background: linear-gradient(89.7877943795121deg, rgb(196, 173, 21) 1%, rgb(244, 241, 92) 80%);
    background: -webkit-linear-gradient(0.212205620487907deg, rgba(196, 173, 21) 1%, rgba(244, 241, 92) 80%);
}
.calendarTimeOffListBackground {
    background: linear-gradient(90deg, rgb(229, 224, 124) 0%, rgb(238, 233, 133) 99%);
    background: -webkit-linear-gradient(0deg, rgb(229, 224, 124) 0%, rgb(238, 233, 133) 99%);
}

/* background border  */
.calendar .fc-day-grid-event {
    border: 0;
    border-radius: 15px;
}
/* day in calendar */
.fc-content {
    border-radius: 15px;
}

/* END FULL CALENDAR  */

carousel {
    .carousel-inner {
        border: 0 !important;
        outline: none !important;
        border-radius: 30px;
        overflow: hidden;
    }

    slide.item {
        animation-duration: 1s;
        animation-fill-mode: both;
        border-radius: 30px;
        &.active {
            outline: none !important;
            border: 0 !important;
            width: 100%;
            animation-name: carouselSlideIn;
        }
        &:not(.active) {
            display: block;
            outline: none !important;
            border: 0 !important;
            animation-name: carouselSlideOut;
        }
        img {
            border-radius: 30px;
        }
    }
    .slide {
        .carousel-indicators li {
            width: 20px;
            height: 20px;
            margin-right: 5px;
            border-radius: 50%;
            background-color: lightblue;
        }
    }
}

@keyframes carouselSlideIn {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes carouselSlideOut {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-105%);
    }
}

//To Ellipsis text in card that overflow
.box-orient-ellipsis {
    text-overflow: ellipsis;
    width: 150px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
}

//Employee, Applicant menu point wrapper style
.employee-header-wrapper,
.applicant-header-wrapper {
    background-color: var(--git-field-label-bg);
    border-radius: 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2rem;
    padding-top: 15px;
    padding: 1rem;
    margin-right: -15px;
    margin-left: -15px;

    .employee-info-wrapper,
    .applicant-info-wrapper {
        display: flex;
        gap: 1rem;

        .avatar {
            box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
        }
    }

    .employee-point-wrapper {
        display: flex;
        gap: 1rem;

        .employee-point-img-wrapper {
            width: 58px;
            height: 58px;
            margin-top: -3px;
            text-align: center;
            border-radius: 5px;
            background: var(--git-field-label-bg);
            box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.15);
            backdrop-filter: blur(5px);
            display: flex;
            justify-content: center;
            align-items: center;

            .employee-point-img {
                width: 58px;
                height: auto;
                border-radius: 5px;
            }
        }
    }
}

.invalidError {
    border-color: var(--git-danger) !important;
}

.btn-header {
    margin-bottom: 0.7rem;
    padding: 0.2rem 1.6rem;
}

.btnHover:hover {
    box-shadow: 1px 1px 5px 5px lightcyan;
    cursor: pointer !important;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}

input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.inputHoverSibling {
    border: 1px solid #cad1d7;
}

.inputHover:focus + .inputHoverSibling {
    border: 1px solid blue;
}

.answer-hint::-webkit-scrollbar {
    width: 8px;
    border-radius: 15px;
}
.answer-hint::-webkit-scrollbar-thumb {
    border-radius: 15px;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: lightgrey;
}

.btnDisabled {
    opacity: 0.3;
    cursor: not-allowed;
}

.img-dark-light {
    background: url("assets/img/theme/white-background-with-blue-tech-hexagon.jpg") no-repeat center / cover;
}

.card-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.card-scrollbar::-webkit-scrollbar-thumb {
    background: lightgrey;
}

.card-scrollbar::-webkit-scrollbar-thumb:hover {
    background: grey;
}

.is-preview {
    background-color: #dedede;
    cursor: default;
}
.darkmode-is-preview {
    background-color: rgb(56, 54, 54);
    cursor: default;
}

.content-information-upload-file {
    font-size: 0.75rem;
    line-height: 1.5;
    display: block;
    width: 100%;
    min-height: calc(2.75rem + 2px);
    transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    color: #8898aa;
    border: 1px solid #cad1d7;
    background-color: var(--git-field-label-bg) !important;
    background-clip: padding-box;
    box-shadow: none;
}

.content-information-upload-file .infomartion-upload label {
    padding: 0.825rem 0.75rem;
}

.content-information-upload-file .infomartion-upload .container-action .fa {
    padding: 0.925rem 0.75rem;
    margin-right: 5px;
    cursor: pointer;
}

.msgShow {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 80%;
    font-size: 13px;
    text-align: center;
    background-color: white;
    width: 95%;
    border: 1px dotted black;
}

.content-upload-file {
    width: 100%;
    cursor: pointer;
}

.list-group-item {
    background-color: transparent;
}

.list-group-item-action-custom:hover {
    background-color: rgb(192, 235, 250);
}

.badge-custom-inprogress {
    background: rgba(249, 177, 21, 1);
    color: #845d73;
}

.badge-custom-completed {
    background: rgba(46, 184, 92, 1);
    color: #ffffff;
}

textarea::-webkit-scrollbar {
    display: none;
}

//Form Input Styling
.form-group {
    position: relative;
    font-size: 0.875rem;
    margin-top: 1rem;
    box-shadow: none !important;

    .form-input-label {
        position: absolute;
        left: 1.5rem;
        color: var(--git-field-label);
        pointer-events: none;
        transform: translateY(0.875rem);
        transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
        font-weight: 600;
    }

    &.required {
        .form-input-label,
        .form-control-label {
            &:after {
                content: "*";
                color: var(--git-danger);
            }
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="password"],
    textarea {
        border: solid 1.5px #dee2e6;
        border-radius: 20px !important;
        padding: 0.875rem;
        font-size: 0.875rem;
        color: var(--git-field-label);
        transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
        width: 100%;
        background-color: transparent;

        &.error-field {
            border: solid 1.5px var(--git-danger) !important;
        }

        &:disabled,
        &:read-only,
        &:focus,
        &:not(:placeholder-shown) {
            ~ label {
                padding: 0 0.2em;
                font-weight: 600;
            }
        }

        &:read-only:not(.datetimepicker-field),
        &:disabled {
            color: var(--git-field-label-hover-disabled);
            background-color: var(--git-field-label-bg-hover-disabled);

            ~ label {
                background-color: transparent !important;
                background-image: linear-gradient(transparent 50%, var(--git-field-label-bg-hover-disabled) 50%);
                color: var(--git-field-label-hover-disabled);
                z-index: 2;
            }
        }

        &.datetimepicker-field:read-only {
            cursor: pointer;
        }

        &:focus,
        &:not(:placeholder-shown) {
            ~ label {
                transform: translateY(-50%) scale(1);
                background-color: var(--git-field-label-bg);
            }
        }

        &:focus {
            outline: none;
            border: 1.5px solid var(--git-field-label-focus) !important;

            ~ label {
                color: var(--git-field-label-focus) !important;
            }
        }

        &:not(:placeholder-shown) {
            ~ label {
                color: var(--git-field-label);
            }
        }
    }

    //Toggle btn styling
    &.toggle-wrapper {
        display: flex;
        padding: 1.5rem 0;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0.3rem !important;
        height: auto;
        gap: 5px;
        flex-direction: row-reverse;

        .form-control-label {
            color: var(--git-field-label);
            margin-bottom: 0 !important;
        }
        .custom-toggle {
            display: flex;

            input[type="checkbox"] {
                ~ .custom-toggle-slider {
                    border: 1px solid #3dc0f0;
                }

                &:disabled {
                    ~ .custom-toggle-slider {
                        &:before,
                        &:after {
                            opacity: 0.7;
                        }
                    }
                }

                &:not(:checked) {
                    ~ .custom-toggle-slider {
                        border: 1px solid var(--git-field-toggle-content);
                        &:before {
                            background-color: var(--git-field-toggle-content);
                        }
                        &:after {
                            color: var(--git-field-toggle-content);
                        }
                    }
                }
            }
        }
    }
}

// bsDatepicker
.bs-datepicker {
    background: var(--git-card-bg) !important;
    box-shadow: none !important;
    .bs-datepicker-head {
        background-color: var(--primary) !important;
        button {
            font-family: "Montserrat";
        }
    }
    .bs-datepicker-body {
        border: 1px solid var(--git-body-bg) !important;
        table {
            thead,
            tbody {
                font-family: "Nunito Sans";
            }
            tr {
                th {
                    color: var(--git-body-color) !important;
                }
                td {
                    &.is-highlighted {
                        span {
                            background-color: var(--primary) !important;
                        }
                    }
                    span {
                        &.selected {
                            background-color: var(--primary) !important;
                        }
                        &.is-highlighted {
                            background-color: var(--primary) !important;
                        }
                        &.in-range {
                            &.select-end {
                                color: #ffffff !important;
                            }
                            color: #313131 !important;
                        }
                    }
                    &.week {
                        span {
                            color: var(--primary) !important;
                        }
                    }
                    .is-other-month {
                        color: var(--git-menu-secondary) !important;
                        opacity: 0.5;
                    }

                    color: var(--git-body-color) !important;
                }
            }
        }
    }
    .bs-datepicker-buttons {
        border-top: 1px solid var(--primary) !important;
        button {
            background: var(--bg-gradient-btn) !important;
            border: none !important;
        }
    }
}

//As of date styling
.as-of-date-wrapper {
    top: 20px;
    left: var(--left-wrapper-width);
    opacity: var(--as-of-date-opacity);

    &.mt-2,
    &.mt-lg-2,
    &.mt-lg-1 {
        margin-top: 0 !important;
    }

    &.ml-4 {
        margin-left: 0 !important;
    }

    .row {
        position: absolute;
        width: 230px;
        z-index: var(--as-of-date-z-index) !important;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .col-lg-4 {
            max-width: 100% !important;
            flex: 0 0 100%;
            padding-left: 0 !important;
            padding-right: 0 !important;

            .input-group {
                display: flex;
                width: fit-content;

                .input-group-text {
                    background-color: transparent;
                    border: none;
                    color: var(--git-field-label) !important;
                }

                span {
                    border-left: 2px solid var(--git-field-label);
                    position: absolute;
                    top: 35%;
                    bottom: 35%;
                }

                input[type="text"] {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    color: var(--git-field-label) !important;
                    max-width: 110px;
                    caret-color: transparent !important;
                }
            }
        }
    }
}

//For Default Content
.form-content-wrapper {
    background-color: var(--git-field-label-bg) !important;
    box-shadow: 1px 1px 6px 1px var(--git-shadowbox-color);
    border-radius: 20px;
    padding: 1rem;
    position: relative;

    // For disabled form Content
    .disabled-form-content-wrapper {
        background: var(--git-disabled-form-content-wrapper);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 20px;
        z-index: 550;
    }

    h2,
    h3 {
        color: var(--primary);
    }

    h5,
    .form-control-label {
        color: var(--git-field-label);
    }
}

//For Modal
.modal {
    .modal-content {
        background-color: var(--git-field-label-bg) !important;
        box-shadow: 0px 0px 10px 0px var(--git-shadowbox-color);

        .modal-header {
            .modal-title {
                color: var(--git-field-label);
            }

            //For X button
            button {
                span {
                    color: var(--git-field-label);
                }
            }
        }

        .form-control-label {
            color: var(--git-field-label);
        }
        h1,
        h2,
        h3,
        h4,
        h5 {
            color: var(--git-field-label);
        }
    }

    .dataTables_wrapper {
        .table-title {
            color: var(--git-field-label) !important;
        }
    }
}

//Hierarchy Component
.google-visualization-orgchart-node.google-visualization-orgchart-node-medium {
    background: linear-gradient(-45deg, var(--git-hierarchy-bg-first-color) 40%, var(--git-hierarchy-bg-second-color) 120%);
    box-shadow: 3px -2px 6px 2px var(--git-hierarchy-box-shadow);
    border: 0;
}

.btnHoverHierarchy {
    color: var(--git-btn-hover-hierarchy) !important;
    cursor: pointer;

    &:hover {
        box-shadow: 1px 1px 5px 5px lightgrey;
        cursor: pointer;
    }
}

.container-google-chart {
    &::-webkit-scrollbar {
        border-radius: 15px;
        background: #3a3b3c !important;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        background: #646669 !important;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #8b8c91 !important;
    }
}

.apexchart-budget-vs-expense,
.apexcharts-legend {
    .apexcharts-legend-series {
        .apexcharts-legend-text {
            color: var(--git-field-label) !important;
        }
    }
}

.apexcharts-yaxis-label,
.apexcharts-xaxis-label {
    fill: var(--git-field-label) !important;
}
